var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","md":"10"}},[(_vm.msConfig.banner)?_c('v-img',{staticClass:"rounded-xl",attrs:{"max-height":"250","src":_vm.msConfig.banner}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"display-1 text-center mb-0 font-weight-bold"},[_vm._v(" Convertirme en miembro ")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"pa-9",staticStyle:{"border":"1px solid black"},attrs:{"rounded":"xl","elevation":"0"}},[_c('v-row',{staticClass:"my-4 mx-0",attrs:{"justify":"start","align":"center"}},[_c('v-avatar',{staticClass:"subheading white--text mr-3",attrs:{"color":"primary darken-2","size":"24"}},[_vm._v(" "+_vm._s(_vm.step)+" ")]),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.currentTitle))])],1),_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-row',[(_vm.hierarchyType != 'ONE_LEVEL')?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-combobox',{staticClass:"pb-0",attrs:{"items":_vm.listBranchesName,"rounded":"","clearable":"","filled":"","placeholder":"Sección"},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1):_vm._e(),_vm._l((_vm.items),function(item,i){return _c('v-col',{key:i,staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","placeholder":item.tag,"rules":item.rule,"rounded":"","filled":"","label":item.tag,"type":item.tag != 'Contraseña'
                      ? 'text'
                      : item.tag == 'Contraseña' && _vm.showPass
                        ? 'text'
                        : 'password',"append-icon":_vm.showPass && item.tag == 'Contraseña'
    ? 'mdi-eye'
    : item.tag == 'Contraseña'
      ? 'mdi-eye-off'
      : ''},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(item.content),callback:function ($$v) {_vm.$set(item, "content", $$v)},expression:"item.content"}})],1)})],2)],1),_c('v-window-item',{attrs:{"value":2}},[_c('v-form',{ref:"extrafields",attrs:{"lazy-validation":""},model:{value:(_vm.extrafieldsForm),callback:function ($$v) {_vm.extrafieldsForm=$$v},expression:"extrafieldsForm"}},[_c('v-row',_vm._l((_vm.extraFields),function(field,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6"}},[_c('FormInput',{attrs:{"field":field,"index":i},on:{"input":_vm.inputHandler}})],1)}),1)],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[(_vm.step == 1)?_c('v-btn',{staticClass:"text-capitalize font-weight-bold white--text px-7 py-2",attrs:{"rounded":"","large":"","loading":_vm.loadingB,"disabled":_vm.disableContinue,"color":_vm.msConfig.buttonColor},on:{"click":function($event){_vm.step++}}},[_vm._v("Siguiente")]):_vm._e(),(_vm.step == 2)?_c('v-btn',{staticClass:"text-capitalize font-weight-bold white--text px-7 py-2",attrs:{"rounded":"","large":"","color":"grey"},on:{"click":function($event){_vm.step--}}},[_vm._v("Volver")]):_vm._e(),(_vm.step == 2)?_c('v-btn',{staticClass:"text-capitalize font-weight-bold white--text px-7 my-4",attrs:{"rounded":"","large":"","disabled":_vm.extrafieldsForm ? false : true,"color":_vm.msConfig.buttonColor},on:{"click":function($event){return _vm.createNewUser()}}},[_vm._v("Registrarme")]):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"max-width":"150","height":"auto","src":require("@/assets/images/weChamber/poweredby.svg")}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }