<template>
  <div>
    <DynamicRegister></DynamicRegister>
  </div>
</template>

<script>
import DynamicRegister from './DynamicRegister.vue'
import micrositeRegister from './micrositeRegister.vue'
export default {
  name: 'MicrositeRegister',
  components: {
    DynamicRegister,
    micrositeRegister
  },
  computed: {
    isAmpi() {
      const affiliationId = this.$route.params.affiliationId
      //dev ampi: 62d707d0cdc3d0001b08cb84
      const ampi1 = '62d707d0cdc3d0001b08cb84'
      const ampi2 = '62d707d0cdc3d0001b08cb84'
      return affiliationId === ampi1 || affiliationId === ampi2
    },
  },
}
</script>

<style>

</style>