<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" class="pt-5">
        <v-img v-if="msConfig.banner" max-height="250" class="rounded-xl" :src="msConfig.banner" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex align-center justify-center">
        <p class="display-1 text-center mb-0 font-weight-bold">
          Convertirme en miembro
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card rounded="xl" elevation="0" style="border: 1px solid black" class="pa-9">
          <v-row justify="start" class="my-4 mx-0" align="center">
            <v-avatar color="primary darken-2" class="subheading white--text mr-3" size="24">
              {{ step }}
            </v-avatar>
            <span class="title">{{ currentTitle }}</span>
          </v-row>

          <v-window v-model="step">
            <v-window-item :value="1">
              <v-row>
                <v-col cols="12" md="6" class="pb-0" v-if="hierarchyType != 'ONE_LEVEL'">
                  <v-combobox :items="listBranchesName" rounded clearable filled placeholder="Sección" class="pb-0"
                    v-model="selectedCompany" />
                </v-col>
                <v-col cols="12" md="6" v-for="(item, i) in items" :key="i" class="pb-0">
                  <v-text-field dense :placeholder="item.tag" v-model="item.content" :rules="item.rule" rounded filled
                    :label="item.tag" :type="item.tag != 'Contraseña'
                        ? 'text'
                        : item.tag == 'Contraseña' && showPass
                          ? 'text'
                          : 'password'
                      " :append-icon="showPass && item.tag == 'Contraseña'
      ? 'mdi-eye'
      : item.tag == 'Contraseña'
        ? 'mdi-eye-off'
        : ''
    " @click:append="showPass = !showPass" />
                </v-col>
              </v-row>
            </v-window-item>

            <v-window-item :value="2">
              <v-form ref="extrafields" v-model="extrafieldsForm" lazy-validation>
                <v-row>
                  <v-col v-for="(field, i) in extraFields" :key="i" cols="12" md="6">
                    <FormInput :field="field" :index="i" @input="inputHandler" />
                  </v-col>
                </v-row>
              </v-form>
            </v-window-item>
          </v-window>

          <v-card-actions class="d-flex justify-center">
            <v-btn v-if="step == 1" class="text-capitalize font-weight-bold white--text px-7 py-2" rounded large
              :loading="loadingB" :disabled="disableContinue" :color="msConfig.buttonColor"
              @click="step++">Siguiente</v-btn>
            <v-btn v-if="step == 2" class="text-capitalize font-weight-bold white--text px-7 py-2" rounded large
              color="grey" @click="step--">Volver</v-btn>
            <v-btn v-if="step == 2" class="text-capitalize font-weight-bold white--text px-7 my-4" rounded large
              :disabled="extrafieldsForm ? false : true" :color="msConfig.buttonColor"
              @click="createNewUser()">Registrarme</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-img max-width="150" height="auto" src="@/assets/images/weChamber/poweredby.svg"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import Endpoints from "@/share/Endpoints";
import { validations } from "@/share/validations.js";
import FormInput from "@/components/shared/FormInput.vue";
import { imageHelper } from "@/utils/imageProcessing.js";

export default {
  name: "DynamicRegister",
  components: {
    FormInput,
  },
  data() {
    return {
      extraFields: [],
      extrafieldsForm: false,
      organization: "",
      hierarchyType: "",
      msConfig: {
        banner: "",
        primaryColor: "black",
        buttonColor: "accent",
        textButtonColor: "white",
      },
      listBranches: [],
      loadingB: false,
      items: [
        {
          tag: "Nombres",
          content: "",
          rule: validations.nameValidation({ name: "nombre", required: true }),
        },
        {
          tag: "Apellidos",
          content: "",
          rule: validations.surnamesValidation({
            name: "apellidos",
            required: true,
          }),
        },
        {
          tag: "Número telefónico",
          content: "",
          rule: validations.phoneValidation({
            name: "teléfono",
            required: true,
          }),
        },
        {
          tag: "Correo electrónico",
          content: "",
          rule: validations.emailValidation(),
        },
        {
          tag: "Verifica tu correo electrónico",
          content: "",
          rule: [],
        },
        {
          tag: "Contraseña",
          content: "",
          rule: this.passwordRules(),
        },
      ],
      showPass: false,
      selectedCompany: "",
      step: 1,
    };
  },
  async created() {
    await this.getAffiliationInfo();
    const response = await this.getRegistrationFields(this.organization);
    if (response.status) {
      this.extraFields = response.data.data;
    }
  },
  computed: {
    disableContinue() {
      if (this.hierarchyType == 'ONE_LEVEL') {
        return this.submitButton ? false : true
      }
      return this.selectedCompany != '' && this.submitButton ? false : true
    },
    currentTitle() {
      return this.step == 1 ? "Información basica" : "Información adicional";
    },
    submitButton() {
      let con = true;
      if (this.selectedCompany == null) {
        this.selectedCompany = "";
      }
      this.items.forEach((e) => {
        if (e.content == "") con = false;
      });
      if (this.items[3].content !== this.items[4].content) {
        this.items[4].rule.push("Asegurese de escribir el mismo correo");
        con = false;
      } else {
        this.items[4].rule = [];
      }
      console.log(con + " " + this.selectedCompany);
      return con;
    },

    listBranchesName() {
      return this.listBranches.map((e) => e.company);
    },
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapActions("user", ["login"]),
    ...mapActions("registrationFields", ["getRegistrationFields"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    inputHandler({ value, index }) {
      this.extraFields[index].value = value;
    },
    passwordRules() {
      return [
        (v) => !!v || "La contraseña es requerida",
        (v) =>
          v.length >= 8 || "La contraseña debe tener al menos 8 caracteres",
        (v) =>
          /[A-Z]/.test(v) ||
          "La contraseña debe tener al menos una letra mayúscula",
        (v) =>
          /[a-z]/.test(v) ||
          "La contraseña debe tener al menos una letra minúscula",
        (v) =>
          /[^A-Za-z0-9]/.test(v) ||
          "La contraseña debe tener al menos un caracter especial",
      ];
    },
    BranchID(company) {
      return this.listBranches.filter((e) => e.company == company);
    },
    async formatDocs() {
      for (let i = 0; i < this.extraFields.length; i++) {
        if (
          this.extraFields[i].fieldType == "DOC" &&
          this.extraFields[i].value &&
          this.extraFields[i].value != null &&
          this.extraFields[i].value != undefined
        ) {
          // if value is a file
          if (this.extraFields[i].value instanceof File) {
            this.extraFields[i].value = await imageHelper.saveImage(
              this.extraFields[i].value
            );
          }
        }
        delete this.extraFields[i].organization;
      }
    },
    async createNewUser() {
      const valid = await this.$refs.extrafields.validate();
      if (!valid) return;
      await this.formatDocs();
      try {
        this.loadingB = true;
        let postUser = {
          isRequireAuthorization: false,
          role: "MEMBER",
          company: this.selectedCompany || "",
          email: this.items[3].content,
          firstLastname: this.items[1].content.split(" ")[0],
          secondLastname: this.items[1].content.split(" ")[1],
          name: this.items[0].content,
          password: this.items[5].content,
          phone: this.items[2].content,
          memberData: this.extraFields,
          headOffice: this.organization
        };

        if (this.hierarchyType != 'ONE_LEVEL') {
          postUser.branch = this.BranchID(this.selectedCompany)[0]._id
        }
        console.log(postUser);

        const response = await this.axios({
          method: "post",
          url: "/members/form",
          data: postUser,
        });

        if (response.status === 200) {
          let user = { email: postUser.email, password: postUser.password };
          let response = await this.login(user);

          if (response.status == true) {
            this.$router.push({
              name: "Member Home",
            }),
              (this.loadingB = false);
          }
        } else {
          this.loadingB = false;
        }
      } catch (error) {
        const response = error.response;
        let message =
          response && response.data && response.data.error
            ? response.data.error.message
            : "algo salio mal intentelo de nuevo mas tarde";

        if (
          message.includes("duplicate key") &&
          message.includes(this.items[3].content)
        ) {
          this.show({
            text: "¡El email ya existe en otra cuenta!",
            color: "error",
          });
        }
        console.log(error);
        console.log(message);
        this.loadingB = false;
      }
    },

    async fetchBranches() {
      this.$route.params.affiliationId;
      const response = await this.axios.get(
        `/branches/membership/${this.$route.params.affiliationId}`
      );
      this.listBranches = response.data.branches;
    },

    async getAffiliationInfo() {
      try {
        this.loading();
        await this.fetchBranches();
        const response = await this.axios.get(
          Endpoints.checkoutAffiliationGetInfo.replace(
            /:affiliationId/,
            this.$route.params.affiliationId
          )
        );
        console.log("affiliation info", response);
        if (response.status === 200) {
          const {
            banner,
            micrositeConfiguration: {
              primaryColor,
              buttonColor,
              textButtonColor,
            },
            affiliationTypes,
            paymentMethods,
            organization: { id, hierarchyType },
          } = response.data.checkoutInformation;
          this.msConfig = {
            banner,
            primaryColor,
            buttonColor,
            textButtonColor,
          };
          this.organization = id;
          this.hierarchyType = hierarchyType;
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loaded();
      }
    },
  },
};
</script>
<style scoped></style>